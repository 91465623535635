<template>
  <v-dialog v-model="infotypeNewDialog" persistent max-width="400">
    <v-card>
      <v-card-title>new speciality</v-card-title>
      <v-card-text>
        <v-text-field
          label="name"
          dense
          v-model="view.name"
          @input="$v.view.name.$touch()"
        ></v-text-field>
        <v-text-field
          dense
          label="description"
          v-model="view.description"
          @input="$v.view.description.$touch()"
          :counter="45"
        ></v-text-field>
        <v-select
          label="subscriber types"
          :items="datalist"
          item-text="name"
          item-value="id"
          v-model="view.subscriber_typesid"
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="$v.view.$invalid"
            >
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "../../../services/RestFul";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  props: {
    infotypeNewDialog: Boolean,
  },
  data() {
    return {
      loading: false,
      responseMessage: null,
      datalist: [],
      view: {
        name: null,
        description: null,
        subscriber_typesid: null,
      },
    };
  },
  validations: {
    view: {
      name: {
        required,
        minLength: minLength(2),
      },
      description: {
        required,
        maxLength: maxLength(45),
      },
    },
  },
  created() {
    this.lists();
  },
  methods: {
    lists() {
      this.loading = true;
      let self = this;
      Restful.general.subscriberTypes
        .list()
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            this.datalist = response.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeNew", payload);
    },
    submit() {
      let payload = { state: false, data: this.view };
      this.$emit("submitNew", payload);
    },
  },
};
</script>
