<template>
  <v-dialog v-model="infoTypeViewDialog" persistent max-width="600">
    <v-card :loading="loading" loader-height="2">
      <v-card-title>{{ name }}</v-card-title>
      <v-card-text>
        <div class="mylist">
          <ul>
            <li>
              <div class="myleft">name</div>
              <div class="myright">{{ view.name }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">description</div>
              <div class="myright">{{ view.description }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">subscriber types</div>
              <div class="myright">{{ view.subscriber_types }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">created</div>
              <div class="myright">{{ view.created | myDating }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">updated</div>
              <div class="myright">{{ view.updated | myDating }}</div>
              <div class="clearall"></div>
            </li>
          </ul>
        </div>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              close
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
//   import moment from "moment";
//   import Utils from "./../../../../mixins/utils";
export default {
  props: {
    infoTypeViewDialog: Boolean,
    myId: { type: Number, default: 0 },
    name: { type: String, default: null },
  },
  // mixins: [Utils],
  data() {
    return {
      loading: false,
      responseMessage: null,
      view: {
        name: null,
        description: null,
        subscriber_types: null,
        created: null,
        updated: null,
      },
    };
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.general.specialties
        .find(this.myId)
        .then((response) => {
          console.log(response.data);
          let data = response.data;
          this.view.name = data.name;
          this.view.created = data.created;
          this.view.description = data.description;
          this.view.subscriber_types = data.subscriber_types;
          this.view.updated = data.updated;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = true;
        });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeView", payload);
    },
  },
};
</script>
